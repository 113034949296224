import React from 'react';
import Privacy from '@/components/organisms/Privacy';
import TermsHeader from '@/components/atoms/Header/TermsHeader';
import TermsFooter from '@/components/atoms/Footer/TermsFooter';
import { Wrapper } from '@/components/layout/common';
import Seo from '@/components/seo';

const PrivacyPage = () => {
  return (
    <Wrapper>
      <Seo title="プライバシーポリシー" />
      <TermsHeader />
      <Privacy />
      <TermsFooter />
    </Wrapper>
  );
};
export default PrivacyPage;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Breakpoints, ContactTel } from '@/constants/constants';
import { Main } from '@/components/layout/common';
import * as util from '@/common/utilities';
import theme from '@/styles/theme';

const StyledMain = styled(Main)`
  font: ${theme.fonts.normal_16_24};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 61px 0 0 0;

  @media screen and (max-width: ${Breakpoints.sp}px) {
    margin: 71px 0 0 0;
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 30px;
`;

const MainArea = styled.div`
  width: 100%;
  max-width: 1062px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 30px;
`;
const Title = styled.h1`
  width: 100%;
  text-align: center;
  font: ${theme.fonts.bold_30_45};
  color: #4c586f;
`;

const Article = styled.article`
  width: 100%;
  margin-top: 30px;
`;

const Crosshead = styled.h2`
  width: 100%;
  text-align: left;
  font-weight: bold;
  font: ${theme.fonts.bold_16_24};
  color: #4c586f;
`;

const Ul = styled.ul`
  padding-left: 1em;
  list-style-type: disc;
`;

const BracketsOl = styled.ol`
  counter-reset: ol-counter;
`;

const BracketsLi = styled.li`
  line-height: 2em;
  list-style-type: none;
  counter-increment: cnt;
  padding-left: 1.5em;
  counter-increment: ol-counter;

  &::before {
    content: '(' counter(ol-counter) ')';
    display: inline-block;
    margin-left: -1.5em;
    width: 1.5em;
  }
`;

const StyleNoneLi = styled.li`
  line-height: 2em;
  list-style: none;
  list-style-position: outside;
  padding-left: 1.25em;
`;

const EncircledNumberSpan = styled.span`
  position: absolute;
  margin-left: -1.25em;
`;

const FormContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
  color: #333333;
  margin-bottom: 10px;
`;

const Row = styled.tr`
  border-top: 1px solid #a2aab0;
  border-bottom: 1px solid #a2aab0;
  text-align: left;
`;

const Th = styled.th`
  width: 299px;
  max-width: 299px;
  vertical-align: top;
  padding: 30px 20px;
  background: #f7f7f7;
  border-right: 1px solid #a2aab0;
  font: ${theme.fonts.bold_16_32};
  color: #333333;
`;

const Td = styled.td`
  width: 763px;
  padding: 30px 0 30px 28px;
`;

const TopTd = styled(Td)`
  padding: 30px 0 30px 28px;
  background: #f7f7f7;
  font: ${theme.fonts.bold_16_32};
  color: #333333;
`;

const Lead = styled.p`
  line-height: 2em;
`;

const Ol = styled.ol`
  counter-reset: ol-counter;
`;

const Li = styled.li`
  line-height: 2em;
  list-style-type: none;
  counter-increment: ol-counter;
  padding-left: 1.5em;
  &::before {
    content: counter(ol-counter, upper-latin);
    display: inline-block;
    margin-left: -1.5em;
    width: 1.5em;
  }
`;

const Privacy = () => {
  const [privacyUrl, setPrivacyUrl] = useState('');
  useEffect(() => {
    setPrivacyUrl(util.cdnPath('pdf/privacypolicy_bae_v2.pdf'));
  }, []);

  return (
    <StyledMain>
      <TitleWrapper>
        <Title>プライバシーポリシー</Title>
      </TitleWrapper>
      <Wrapper>
        <MainArea>
          <Lead>個人情報及び特定個人情報の取扱いについて（保有個人データに関する事項の公表）</Lead>
          <Lead>
            株式会社ワークスベイ（以下「当社」という。）は、「個人情報及び特定個人情報保護方針」に基づき、当社が事業の用に供する個人情報及び特定個人情報等を次の通り取扱います。
          </Lead>
          <Article>
            <Crosshead>1.個人情報及び特定個人情報等の利用目的</Crosshead>
            <BracketsOl>
              <BracketsLi>直接書面により取得した個人情報の利用目的</BracketsLi>
              <FormContainer>
                <tbody>
                  <Row>
                    <Th>対象者</Th>
                    <TopTd>利用目的</TopTd>
                  </Row>
                  <Row>
                    <Th>お客様</Th>
                    <Td>
                      <BracketsOl>
                        <BracketsLi>売買取引における物件情報をご案内するため （電話・ダイレクトメール等）</BracketsLi>
                        <BracketsLi>売買取引における契約を履行するため</BracketsLi>
                        <BracketsLi>売買取引におけるアフターサービスを実施するため</BracketsLi>
                        <BracketsLi>賃貸借の代理・仲介における契約を履行するため</BracketsLi>
                        <BracketsLi>損害保険代理業における募集・契約締結・管理のため</BracketsLi>
                        <BracketsLi>住宅瑕疵担保責任保険における契約を履行するため</BracketsLi>
                        <BracketsLi>お客様の満足度を把握するための各種調査等を実施するため</BracketsLi>
                      </BracketsOl>
                    </Td>
                  </Row>
                  <Row>
                    <Th>お取引先</Th>
                    <Td>業務推進の連絡のため</Td>
                  </Row>
                  <Row>
                    <Th>従業員及び採用応募者</Th>
                    <Td>採用選考、人事労務管理、および個人番号については、個人番号法で定められた事務のため</Td>
                  </Row>
                  <Row>
                    <Th>お問合せ</Th>
                    <Td>お問合せ対応のため</Td>
                  </Row>
                </tbody>
              </FormContainer>
              <BracketsLi>直接書面以外の方法によって取得した個人情報の利用目的</BracketsLi>
              <FormContainer>
                <tbody>
                  <Row>
                    <Th>業務内容</Th>
                    <TopTd>利用目的</TopTd>
                  </Row>
                  <Row>
                    <Th>
                      共同利用：
                      <br />
                      当社は、グループ会社間で、お客様、及び従業員の個人情報を共同利用します。
                    </Th>
                    <Td>
                      <BracketsOl>
                        <BracketsLi>共同して利用する個人情報</BracketsLi>
                        <Ul>
                          <li>
                            お客様情報：氏名・住所・電話番号・勤務先・メールアドレス、物件情報等、その他お客様との取引に関する情報
                          </li>
                          <li>従業員情報：氏名、住所、評価・報酬情報</li>
                        </Ul>
                        <BracketsLi>共同して利用する者の範囲</BracketsLi>
                        <Lead>
                          ワークスグループ株式会社・株式会社日本ワークス・株式会社ワークスヴィア・株式会社Core Asset Management・株式会社ワークス合人社
                        </Lead>
                        <BracketsLi>利用する者の利用目的</BracketsLi>
                        <Ul>
                          <li>グループ全体としてお客様に対するアフターサービスを実施するため</li>
                          <li>グループ全体としてお客様の満足度を把握するための各種調査等を実施するため</li>
                          <li>グループ会社において取扱う商品・サービス等をご案内するため</li>
                          <li>グループ間での人事・評価情報を共有するため</li>
                        </Ul>
                        <BracketsLi>
                          共同利用する個人情報の管理について責任を有する事業者：株式会社ワークスベイ
                        </BracketsLi>
                        <Lead>所在地：東京都渋谷区桜丘町20-1 渋谷インフォスタワー7F</Lead>
                        <Lead>代表者：代表取締役　上木 芳明</Lead>
                      </BracketsOl>
                    </Td>
                  </Row>
                </tbody>
              </FormContainer>
              <BracketsLi>
                当社は個人情報を以下の場合を除き、第三者に開示又は提供いたしません。
                <ol>
                  <StyleNoneLi>
                    <EncircledNumberSpan>&#9312;</EncircledNumberSpan>ご本人の同意がある場合
                    <Lead>
                      当社が保有する個人情報は、お客様との契約を履行するため、その業務内容に応じて、氏名・住所・物件情報を、書面・電話・広告媒体等の手段により、次の（1）から（6）の第三者に提供されます。なお、お客様からのお申し出により、提供は停止致します。
                    </Lead>
                    <BracketsOl>
                      <BracketsLi>売買取引における融資に関する金融機関</BracketsLi>
                      <BracketsLi>売買取引における登記に関する司法書士・土地家屋調査士</BracketsLi>
                      <BracketsLi>広告掲載事業者</BracketsLi>
                      <BracketsLi>他の宅地建物取引業者</BracketsLi>
                      <BracketsLi>保険取扱機関</BracketsLi>
                      <BracketsLi>お客様から委託を受けた事項についての契約の相手方となる者・その見込者</BracketsLi>
                    </BracketsOl>
                  </StyleNoneLi>
                  <StyleNoneLi>
                    <EncircledNumberSpan>&#9313;</EncircledNumberSpan> 法令などで認められた場合
                  </StyleNoneLi>
                  <StyleNoneLi>
                    <EncircledNumberSpan>&#9314;</EncircledNumberSpan>{' '}
                    人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき
                  </StyleNoneLi>
                  <StyleNoneLi>
                    <EncircledNumberSpan>&#9315;</EncircledNumberSpan>
                    公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
                  </StyleNoneLi>
                </ol>
              </BracketsLi>
            </BracketsOl>
          </Article>
          <Article>
            <Crosshead>2. 保有管理データの安全管理措置 </Crosshead>
            <BracketsOl>
              <BracketsLi>
                基本方針の策定
                <Lead>
                  個人データの適切な取扱い確保のため、「関係法令・ガイドライン等の遵守」、「質問及び苦情処理の窓口」等についての基本方針を策定
                </Lead>
              </BracketsLi>
              <BracketsLi>
                個人データの取扱いに係る規律の整備
                <Lead>個人データの取得、利用、保存等を行う場合の基本的な取扱方法を整備</Lead>
              </BracketsLi>
              <BracketsLi>
                組織的安全管理措置
                <Lead>整備した取扱い方法に従って個人データが取り扱われていることを責任者が確認</Lead>
              </BracketsLi>
              <BracketsLi>
                人的安全管理措置
                <Lead>個人データの取扱いに関する留意事項について、従業者に定期的な研修を実施</Lead>
              </BracketsLi>
              <BracketsLi>
                物理的安全管理措置
                <Lead>
                  個人データを取り扱うことのできる従業者及び本人以外が容易に個人データを閲覧できないような措置を実施
                </Lead>
              </BracketsLi>
              <BracketsLi>
                技術的安全管理措置
                <Lead>
                  個人データを取り扱う機器を外部からの不正アクセス又は不正ソフトウェアから保護する仕組みを導入
                </Lead>
              </BracketsLi>
              <BracketsLi>
                外部環境の把握
                <Lead>
                  外国にある事業者へ委託する場合は、委託先の安全管理について確認・点検するとともに、当該国における個人情報保護法制について確認（個人情報保護委員会）
                </Lead>
              </BracketsLi>
            </BracketsOl>
          </Article>
          <Article>
            <Crosshead>3. 個人情報及び特定個人情報等のお問い合わせ、開示等手続きについて</Crosshead>
            <BracketsOl>
              <BracketsLi>
                ご本人またはその代理人が、個人情報及び特定個人情報等に関して、利用目的の通知、開示、内容の訂正・追加又は削除、利用の停止、消去及び第三者への提供の停止（以下、「開示等」という。）を要求される場合には誠実に対応いたします。
              </BracketsLi>
              <BracketsLi>お問い合わせに際し、ご本人またはその代理人であるかを確認する場合があります。</BracketsLi>
              <BracketsLi>
                ご本人または、その代理人としてのご本人確認が取れない場合は、お問い合わせ、開示等のご要望に応じられない場合があります。
              </BracketsLi>
              <BracketsLi>
                ご本人またはその代理人からの個人情報及び特定個人情報等の開示請求、当社からの個人情報の開示回答は合理的期間内に回答させていただきます。
              </BracketsLi>
            </BracketsOl>
          </Article>
          <Article>
            <Crosshead>【開示等の手続きの方法】</Crosshead>
            <Lead>
              個人情報について開示等のご請求は、カスタマーサポートセンター宛に所定の書面にてお願い申し上げます。
              尚、手続きにあたって、利用目的の通知または開示のお返事を郵送でご希望の場合には、所定の手数料1,000円(税抜)をご負担頂きます。
              手続きの詳細は以下をご覧下さい。
            </Lead>
            <BracketsOl>
              <BracketsLi>
                開示等の対象としている項目は、次の通りです。
                <Lead>氏名・住所・電話番号・勤務先名</Lead>
              </BracketsLi>
              <BracketsLi>
                開示等の請求に際してご提出頂く書面（様式）等
                <Lead>
                  開示等の請求は、次の所定の書面（A）をダウンロードし、
                  所定事項をご記入の上、ご本人確認のための書類（B）、を同封の上、カスタマーサポートセンター宛にご郵送下さい。
                </Lead>
                <Ol type="A">
                  <Li>
                    当社の所定の書面
                    <Lead>
                      個人情報開示等請求書（PDFファイル)
                      <a href={privacyUrl}>【ダウンロード】</a>
                    </Lead>
                  </Li>
                  <Li>
                    本人確認のための書類
                    <Lead>運転免許証・パスポート・健康保険証の写し等</Lead>
                  </Li>
                </Ol>
              </BracketsLi>
              <BracketsLi>
                個人情報開示等請求書を受付後、当社にて開示等の手配が完了した時点で口座振込依頼書を送付いたしますので、口座振込によりお支払をお願いします。
                <Lead>※ 振込手数料はご請求者様の負担とさせていただきます。</Lead>
                <Lead>※口座振込以外のお支払はお取扱いいたしませんのでご了承願います。</Lead>
              </BracketsLi>
              <BracketsLi>
                代理人による開示等の請求
                <Lead>
                  代理人が開示等の請求をされる場合は、上記(2)の書類に加えて、委任状、代理人の運転免許証等の写し及びご本人の印鑑登録証明証を同封して下さい。
                </Lead>
              </BracketsLi>
              <BracketsLi>
                開示等の請求に対する回答方法
                <Lead>
                  ご本人の個人情報であることが確認できましたら、合理的期間内に、ご本人様宛、書面または電子的方法（PW付添付ファイルを電子メールで送信、またはダウンロードリンクを電子メールでご案内）のうちご本人のご希望の方法にてご回答いたします。
                </Lead>
              </BracketsLi>
              <BracketsLi>
                開示等の請求に伴い取得した個人情報の利用目的
                <Lead>
                  開示等の請求に伴い取得した個人情報は、請求の回答に必要な範囲内のみで取扱うものとします。
                  ご提出頂いた書類は、開示等の請求に対する回答が終了した後、２年間保存し、その後廃棄させ て頂きます。
                </Lead>
              </BracketsLi>
              <BracketsLi>
                個人情報の不開示事由について
                <Lead>以下の場合は、不開示とさせて頂きます。その場合、理由を付記して通知致します。</Lead>
                <Ul>
                  <li>ご本人の確認ができない場合、又は代理人申請の場合で、代理権が確認できない場合</li>
                  <li>所定の申請書類に不備があった場合、 所定の手数料をお支払いただけない場合</li>
                  <li>本人又は第三者の生命・身体・財産その他の権利利益を害するおそれがある場合</li>
                  <li>当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</li>
                  <li>他の法令に違反することとなる場合</li>
                </Ul>
              </BracketsLi>
            </BracketsOl>
          </Article>
          <Article>
            <Lead>個人情報及び特定個人情報等の開示等及び苦情・相談の窓口</Lead>
            <Lead>お問い合わせ先：株式会社ワークスベイ</Lead>
            <Lead>TEL 03-3770-2703</Lead>
            <Lead>〒150-0031 東京都渋谷区桜丘町20-1 渋谷インフォスタワー7F</Lead>
          </Article>
        </MainArea>
      </Wrapper>
    </StyledMain>
  );
};

export default Privacy;
